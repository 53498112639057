import axios from 'axios';
import { objectString } from '@utils';
import { BASE_EXAM as BASE_V1, BASE_EXAM_QUIZ as BASE } from '@http';

const headers = (token) => {
  let data = {
    'Content-Type': 'application/json',
  };

  if (token) {
    data = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Content-Type-Options': 'nosniff',
      'Access-Control-Allow-Origin': '*',
    };
  }

  return data;
};

export const requestPost = (url, data, token, params) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .post(`${BASE}${url}`, data, {
        headers: headers(token),
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestGet = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE}${url}${params ? objectString(params) : ''}`, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestGetV1 = (url, params, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(`${BASE_V1}${url}${params ? objectString(params) : ''}`, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .then((error) => {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPut = (url, params, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .put(`${BASE}${url}${params ? objectString(params) : ''}`, body, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestPatch = (url, params, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .patch(`${BASE}${url}${params ? objectString(params) : ''}`, body, {
        headers: headers(token),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const requestDelete = (url, body, token) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .delete(`${BASE}${url}`, {
        headers: headers(token),
        data: body,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const requestAnyGet = (url, params, headers) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    axios
      .get(
        `${BASE}${objectString(params)}`,
        headers && {
          headers,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
