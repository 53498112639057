import { requestGet, requestPost, requestPatch } from '@configs/exam';
import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { notification } from 'antd';
import _get from 'lodash/get';
import {
  COLLECT_ANSWER,
  FETCH_EXAM_QUESTION,
  FETCH_STUDENT_EXAM,
  READ_QNA_EXAM,
  READ_STUDENT_EXAM,
  SUBMIT_FACE_DETECTION,
  SUBMIT_STUDENT_EXAM,
} from '../../../../constants/initType';
import { getStorage, setStorage } from '../../../../utils/localStorage.js';
import { convertToStorage, handleCollectAnswer } from '../../../reducers/MediaKelas/UjianSiswa/functions';
import { requestGetV1 } from '../../../../configs/exam';
import { BASE_SOCKET_SERVICE } from '../../../../constants/initHttp';
import httpService from '../../../../configs/service';
// import { READ_STUDENT_EXAM } from "@type";

export const fetchStudentExam = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('exam/me', params, getUserToken());
    dispatch({ type: FETCH_STUDENT_EXAM, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const fetchStudentExamV1 = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGetV1('exam', params, getUserToken());
    dispatch({ type: FETCH_STUDENT_EXAM, value: rest.payload });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const readStudentExam = (id, isHardSave) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`exam/${id}`, null, getUserToken());
    const currentDetail = getStorage('examDetail') || {};
    isHardSave && setStorage('examDetail', { ...currentDetail, ...rest.data });
    dispatch({ type: READ_STUDENT_EXAM, value: rest.data });
  } catch (error) {
    // notification.error({
    //     message: "Mohon Maaf!",
    //     description: "Silahkan Hubungi Customer Service Kami",
    // });
  }
  dispatch(showLoading(false));
};

export const readExamParticipant = (examId, userId, isHardSave) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`exam/${examId}/participant/${userId}?show_detail=true`, null, getUserToken());
    const currentDetail = getStorage('examDetail') || {};
    isHardSave &&
      setStorage('examDetail', {
        ...currentDetail,
        remaining_date_unix: rest.data.remaining_date_unix,
        is_start: rest.data.is_start,
        is_end: rest.data.is_end,
        total_score: rest.data.total_score,
        score: rest.data.score,
        exam_status: rest.data.exam_status,
      });
    dispatch({
      type: READ_STUDENT_EXAM,
      value: {
        remaining_date_unix: rest.data.remaining_date_unix,
        is_start: rest.data.is_start,
        is_end: rest.data.is_end,
        total_score: rest.data.total_score,
        score: rest.data.score,
        exam_status: rest.data.exam_status,
        is_submitted_by_user: rest.data.is_submitted_by_user,
      },
    });
    return rest;
  } catch (error) {
    // notification.error({
    //     message: "Mohon Maaf!",
    //     description: "Silahkan Hubungi Customer Service Kami",
    // });
  }
  dispatch(showLoading(false));
};

export const readQnA = (examId, participantId) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet(`qa/preview/v2/${examId}/${participantId}`, null, getUserToken());
    dispatch({ type: READ_QNA_EXAM, value: rest.data });
  } catch (error) {
    // notification.error({
    //     message: "Mohon Maaf!",
    //     description: "Silahkan Hubungi Customer Service Kami",
    // });
  }
  dispatch(showLoading(false));
};

export const validateExamToken = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('exam/validate/token', data, getUserToken());

    dispatch({
      type: SUBMIT_FACE_DETECTION,
    });
    dispatch(showLoading(false));
    return rest.data;
  } catch (error) {
    notification.error({
      message: 'Mohon Maaf!',
      description: 'Token tidak valid, silahkan hubungi guru anda!',
    });
    dispatch(showLoading(false));
    throw error.response.data;
  }
};

export const generalFaceDetection = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost('detection/upload', data, getUserToken());

    dispatch({
      type: SUBMIT_FACE_DETECTION,
    });
    dispatch(showLoading(false));

    return rest.data;
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const validateStartExam = (examId, userId, data) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestPost(`exam/${examId}/participant/${userId}/start/validate`, data, getUserToken());

    dispatch({
      type: SUBMIT_FACE_DETECTION,
    });
    dispatch(showLoading(false));
    return rest.data;
  } catch (error) {
    // notification.error({
    //     message: "Mohon Maaf!",
    //     description: "Silahkan Hubungi Customer Service Kami",
    // });
    dispatch(showLoading(false));
    throw error.response.data;
  }
};

export const ongoingExamFaceDetection = (examId, userId, data) => async (dispatch) => {
  try {
    const rest = await requestPost(`exam/${examId}/participant/${userId}/detect/face`, data, getUserToken());

    dispatch({
      type: SUBMIT_FACE_DETECTION,
    });
    dispatch(showLoading(false));

    return rest.data;
  } catch (error) {
    return false;
  }
};

export const cheatDetection = (examId, userId, type) => async (dispatch) => {
  try {
    const rest = await requestPost(`exam/${examId}/participant/${userId}/detect/${type}`, null, getUserToken());

    dispatch({
      type: SUBMIT_FACE_DETECTION,
    });
    dispatch(showLoading(false));

    return rest.data;
  } catch (error) {
    // console.log('submitCheatDetection Error!');
  }
};

export const fetchExamQuestions = (params) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const rest = await requestGet('question', params, getUserToken());

    const localExamp = new Map();
    localExamp.set(params?.exam_id, rest.data);

    setStorage('examQuestions', JSON.stringify(Array.from(localExamp.entries())));

    dispatch({ type: FETCH_EXAM_QUESTION, value: rest.data });
  } catch (error) {
    // notification.error({
    //   message: 'Mohon Maaf!',
    //   description: 'Silahkan Hubungi Customer Service Kami',
    // });
  }
  dispatch(showLoading(false));
};

export const collectAnswer = (data) => (dispatch) => {
  const currentCollection = getStorage('examAnswerCollection') || [];
  setStorage('examAnswerCollection', handleCollectAnswer(data, currentCollection));
  dispatch({ type: COLLECT_ANSWER, data });
};

export const submitExamAnswers = (data, throwError = false) => async (dispatch) => {
  dispatch(showLoading(true));
  try {
    const req = await requestPost('answers', data, getUserToken());

    dispatch({
      type: SUBMIT_STUDENT_EXAM,
    });
    console.log('req_soal', req);
    // return rest.data;
  } catch (error) {
    console.log('error2', error);
    if (throwError) {
      throw error;
    }
    // notification.error({
    //     message: "Mohon Maaf!",
    //     description: "Silahkan Hubungi Customer Service Kami",
    // });
    return false;
  }
  dispatch(showLoading(false));
  return true;
};

export const commitStartExam = ({ examId, userId, params }) => {
  const api = async (dispatch) => {
    dispatch(showLoading(true));
    try {
      const rest = await requestPost(`exam/${examId}/participant/${userId}/start`, null, getUserToken(), params);
      dispatch({
        type: 'SUBMIT_ANSWER',
      });
      dispatch(showLoading(false));
      return rest.data;
    } catch (error) {
      dispatch(showLoading(false));
      throw error;
    }
  };
  return api;
};

export const setFinalScore = ({ examId, userId, data }) => {
  const api = async (dispatch) => {
    dispatch(showLoading(true));
    try {
      const rest = await requestPatch(`exam/${examId}/participant/${userId}/finalScore`, {}, data, getUserToken());
      dispatch(showLoading(false));
      return rest.data;
    } catch (error) {
      dispatch(showLoading(false));
      throw error;
    }
  };
  return api;
};

export const commitEndExam = ({ examId, userId }) => {
  const api = async (dispatch) => {
    dispatch(showLoading(true));
    try {
      const rest = await requestPost(
        `exam/${examId}/participant/${userId}/end?isSubmittedByUser=true`,
        null,
        getUserToken()
      );
      dispatch({
        type: 'SUBMIT_ANSWER',
      });

      localStorage.removeItem('examDetail');
      // localStorage.removeItem('examAnswerCollection');
      localStorage.removeItem('examQuestions');
      dispatch(showLoading(false));
      // console.log("res", rest);
      return rest.data;
    } catch (error) {
      dispatch(showLoading(false));
      throw error;
    }
  };
  return api;
};

export const checkpointExamAnswers = (data) => async () => requestPost('answers', data, getUserToken());
// } catch (error) {
// throw error;
// }
export const postExamChat = (id, data) => async () => {
  try {
    const res = await requestPost(`exam/${id}/examChat`, data, getUserToken());
    if (res.code === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
};
export const getExamChat = (id, params) => async () => {
  try {
    const res = await requestGet(`exam/${id}/examChat`, params, getUserToken());
    return res.data;
  } catch (error) {
    return false;
  }
};

export const getAllAnswers = (params) => async (dispatch) => {
  try {
    const res = await requestGet('answer', params, getUserToken());
    const resAnswerCollections = _get(res, 'data', []) || [];
    const resConvertAnswer = convertToStorage(resAnswerCollections);

    const currentStorage = getStorage('examAnswerCollection');
    if (!currentStorage || currentStorage.length === 0) {
      setStorage('examAnswerCollection', resConvertAnswer);
      dispatch(setExamData('answerCollection', resConvertAnswer));
    }
    return res;
  } catch (error) {
    return false;
  }
};

// Force set data reducer
export const setExamData = (path, data) => (dispatch) => {
  dispatch({ type: 'FORCE_SET_DATA', path, data });
};

// clear action reducers
// export const clearCreateBankSoal = () => ({
//     type: CREATE_BANK_SOAL,
//     value: false,
// });

export const checkRoom = (params) =>
  httpService.get('check-room', {
    baseURL: BASE_SOCKET_SERVICE,
    params,
  });
