module.exports = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  BASE_API_PAYMENT: process.env.REACT_APP_BASE_PAYMENT,
  BASE_API_LPA: process.env.REACT_APP_BASE_LPA,
  BASE_VERSION: process.env.REACT_APP_BASE_VERSION,
  BASE_LOGIN: process.env.REACT_APP_BASE_LOGIN,
  BASE_EVALUASI: process.env.REACT_APP_BASE_EVALUASI,
  BASE_INTERAKSI: process.env.REACT_APP_BASE_INTERAKSI,
  BASE_INTERAKSI_SOCKET: process.env.REACT_APP_BASE_INTERAKSI_SOCKET,
  BASE_COURSE: process.env.REACT_APP_BASE_COURSE,
  BASE_EXAM: process.env.REACT_APP_BASE_EXAM,
  BASE_QUIZ: process.env.REACT_APP_BASE_QUIZ,
  BASE_EXAM_QUIZ: process.env.REACT_APP_BASE_EXAM_QUIZ,
  BASE_REKAP_NILAI: process.env.REACT_APP_BASE_REKAP_NILAI,

  BASE_STUDY: process.env.REACT_APP_BASE_STUDY,
  BASE_SOCKET: process.env.REACT_APP_BASE_URL_SOCKET,
  BASE_SOCKET_SERVICE: `${process.env.REACT_APP_BASE_API_SOCKET ?? 'https://dev-socket-ws.siswamedia.com'}/v1/api/`,
  BASE_IDENTITY: process.env.REACT_APP_BASE_LOGIN,
  BASE_PROXY: process.env.REACT_APP_PROXY_SERVER,
  BASE_SOCKET_EXAM: process.env.REACT_APP_BASE_SOCKET_EXAM,
};
