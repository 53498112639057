import _remove from 'lodash/remove';
import _trim from 'lodash/trim';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _split from 'lodash/split';
import _join from 'lodash/join';
import { MULTIPLE_ANSWERS_SEPARATOR } from '../../../../constants/initCongs';

function cleanStrHtml(string) {
  // eslint-disable-next-line no-useless-escape
  string = string.replace(/\&nbsp;/g, '').replace(/<[^>]+>/g, '');

  return string;
}

export const handleCollectAnswer = (newData, currentTable) => {
  const { question_id, qbank_question_type, value, attachment, exam_id } = newData;
  let tempAnswers = [];

  const existedAnswers = currentTable.filter((ans) => ans.question_id === question_id && ans.exam_id === exam_id);
  const isAnswerExist = existedAnswers.length > 0;

  const addedCurrentDataAnswers = [...currentTable, newData];

  if (qbank_question_type === 3) {
    /* -------------------------------------------------------------------------- */
    /*                      HANDLE QUIZ TYPE MULTIPLE ANSWER                      */
    /* -------------------------------------------------------------------------- */

    // ? example value multiple "<p>0</p>", "<p>2</p>", convert to "<p>0</p>|||<p>2</p>",

    /* --------------------- step 1: convert array new value -------------------- */
    const parseValues = value.split(MULTIPLE_ANSWERS_SEPARATOR);

    /* ------------------- step 2 : handle and get prev value ------------------- */
    const findValue = _get(
      _find(currentTable, (o) => o.question_id === question_id && o.exam_id === exam_id),
      'value',
      ''
    );
    const prevValues = findValue ? _split(findValue, MULTIPLE_ANSWERS_SEPARATOR) : [];

    /* --------------------- step 3: array temporary process -------------------- */
    let tmpMultiAns = [];
    if (_includes(prevValues, value)) {
      tmpMultiAns = _filter(prevValues, (n) => n !== value);
    } else {
      tmpMultiAns = [...prevValues, parseValues];
    }

    /* --------------------- step 4: array to string convert -------------------- */
    const toStringValue = _join(tmpMultiAns, MULTIPLE_ANSWERS_SEPARATOR);

    /* ----------------------------- step 5: execute ---------------------------- */

    if (isAnswerExist) {
      tempAnswers = [
        ...currentTable.filter((ans) => !(ans.question_id === question_id && ans.exam_id === exam_id)),
        { ...newData, value: toStringValue },
      ];
    } else {
      tempAnswers = addedCurrentDataAnswers;
    }
    /* -------------------------------------------------------------------------- */
    /*                    END HANDLE QUIZ TYPE MULTIPLE ANSWER                    */
    /* -------------------------------------------------------------------------- */
  } else if (isAnswerExist) {
    tempAnswers = [
      ...currentTable.filter((ans) => !(ans.question_id === question_id && ans.exam_id === exam_id)),
      newData,
    ];
  } else {
    tempAnswers = addedCurrentDataAnswers;
  }

  if (attachment?.length > 0) {
    return tempAnswers;
  }
  if (![1, 3].includes(qbank_question_type)) {
    const getterValue = _get(newData, 'value', '') || '';
    if (_trim(cleanStrHtml(getterValue)) === '') {
      _remove(tempAnswers, (o) => o.qbank_question_id === newData?.qbank_question_id && o.exam_id === exam_id);
    }
  }

  return tempAnswers;
};

export const convertToStorage = (rows) => {
  const arr = [];
  rows.forEach((data) => {
    const obj = {
      exam_id: data?.exam_id,
      question_id: data?.question_id,
      qbank_quiz_id: data?.qbank_quiz_id,
      qbank_question_id: data?.qbank_question_id,
      qbank_question_type: data?.qbank_question_type,
      participant_id: data?.participant_id,
      value: data?.value,
    };

    const extraObj = {
      attachment: data?.attachment,
    };

    if (data?.value) {
      if (data?.qbank_question_type === 2) {
        arr.push({ ...obj, ...extraObj });
      } else {
        arr.push(obj);
      }
    }
  });

  return arr;
};
